import gsap from 'gsap'

import { GridHelper } from 'three'

import Experience from '../Experience.js'
import Environment from './Environment.js'
import Text from './Text.js'
import Railway from './Railway.js'
import Raycaster from './Raycaster.js'
import Sueyoungshim from './Sueyoungshim.js'

import Berkeley from './Stations/Berkeley.js'
import SWiiFT from './Stations/SWiiFT.js'
import Halloween from './Stations/Halloween.js'
import Domino from './Stations/Domino.js'
import Construction from './Stations/Construction.js'
import SantasWorkshop from './Stations/SantasWorkshop.js'
import WhatsNext from './Stations/WhatsNext.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        this.camera = this.experience.camera

        this.stations = {
            'start': { x: -50, y: 0 },
            'intro': { x: -30, y: 0 },
            'berkeley': { x: -10, y: 0 },
            'swiift': { x: -10, y: -10 },
            'halloween': { x: -5, y: -10 },
            'domino': { x: -5, y: 10 },
            // 'construction': { x: -5, y: 10 },
            'workshop': { x: 7, y: 10 },
            'whatsnext': { x: 7, y: 5 },
            'end': { x: 30, y: 5 },
        }
        this.stationsArr = Object.keys(this.stations)

        this.currentIndex = 2
        // Wait for resources 
        this.resources.on('ready', () =>
        {
            // Setup
            this.text = new Text()
            this.environment = new Environment()

            this.railway = new Railway()
            this.sueyoungshim = new Sueyoungshim()

            this.raycaster = new Raycaster()

            // Stations
            this.berkeley = new Berkeley()
            this.swiift = new SWiiFT()
            this.halloween = new Halloween()
            this.domino = new Domino()
            // this.construction = new Construction()
            this.workshop = new SantasWorkshop()
            this.whatsnext = new WhatsNext()
        })

        this.setSequence()
        this.setDebug()
    }

    setSequence()
    {
        let station
        window.addEventListener('keydown', (ev) => {
            // this.camera.setBirdsEyeView()

            switch (ev.code) {
                case 'ArrowLeft':
                case 'KeyA':
                    if (this.currentIndex > 1) 
                    { 
                        this.currentIndex-- 
                        station = this.stationsArr[this.currentIndex]
                        this.raycaster.moveSueyoungshim(station)
                        this.updateFocusedStation(station)
                    }
                    break
    
                case 'ArrowRight':
                case 'KeyD':
                case 'Space':
                    if (this.currentIndex < this.stationsArr.length - 2) 
                    { 
                        this.currentIndex++ 
                        station = this.stationsArr[this.currentIndex]
                        this.raycaster.moveSueyoungshim(station)
                        this.updateFocusedStation(station)

                        if (station == 'whatsnext')
                        {
                            // this.whatsnext.setCamera()
                        }
                    }
                    else 
                    {
                        // this.camera.setBirdsEyeView()
                    }
                    break
            }
        })
        // Scroll Event
        // window.addEventListener('wheel', (event) => {
        //     console.log('wheel event')
            
        //     // Update PARAMS.t based on scroll direction
        //     const delta = event.deltaY > 0 ? 0.01 : -0.01 // Adjust speed as needed
        //     const newT = this.sueyoungshim.PARAMS.t + delta

        //     // Clamp PARAMS.t between 0 and 1
        //     this.sueyoungshim.PARAMS.t = Math.min(Math.max(newT, 0), 1)
            

        //     // Move Sueyoungshim letters
        //     this.sueyoungshim.moveMesh(this.sueyoungshim.PARAMS.t)
        // })

        window.addEventListener('wheel', (event) => {
            // const scrollFactor = 0.005
            const delta = event.deltaY > 0 ? 0.01 : -0.01
            const targetT = Math.min(Math.max(this.sueyoungshim.PARAMS.t + delta, 0), 1)
        
            gsap.to(this.sueyoungshim.PARAMS, {
                t: targetT,
                duration: 0.5,
                ease: 'power2.out',
                onUpdate: () => {
                    this.sueyoungshim.moveMesh(this.sueyoungshim.PARAMS.t)
        
                    // Sync with the closest station
                    const closestStationIndex = Math.round(this.sueyoungshim.PARAMS.t * (this.stationsArr.length - 1))
                    if (closestStationIndex !== this.currentIndex) {
                        this.currentIndex = closestStationIndex
                        const station = this.stationsArr[this.currentIndex]
                        this.updateFocusedStation(station)
                    }
                },
            })
        })
        



    }

    updateFocusedStation(focused) {
        this.stationsArr.forEach((station) => {
            if (this[station]) {
                this[station].focused = (station === focused)
            }
        })
    }

    update()
    {
        if (this.berkeley) {
            this.berkeley.update()
        }
        if (this.swiift) {
            this.swiift.update()
        }
        if (this.halloween) {
            this.halloween.update()
        }
        if (this.sueyoungshim) {
            this.sueyoungshim.update()
        }
    }

    setDebug() 
    {
        if (!this.debug.active) return
    
        this.debugFolder = this.debug.pane.addFolder({ 
            title: 'Stations',
            expanded: false
        })
    
        const stations = ['start', 'intro', 'berkeley', 'swiift', 'halloween', 'domino', 'workshop', 'whatsnext', 'end'];
        const bindingOptions = {
            x: { min: -30, max: 30 },
            y: { min: -30, max: 30 }
        }
    
        stations.forEach(station => {
            this.debugFolder.addBinding(this.stations, station, bindingOptions).on('change', () => {
                this.railway.clear();
                this.railway = new Railway();
            })
        })
    }
}
