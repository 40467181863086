import * as THREE from 'three'
import Experience from '../../Experience.js'

export default class SantasWorkshop
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug
        this.world = this.experience.world

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder({
                title: "santa's workshop",
                expanded: false
            })
        }

        // Resource
        this.workshopModel = this.resources.models.workshop
        this.textures = this.resources.textures

        this.position = this.world.stations.workshop
        this.focused = false

        this.meshes = {}
        this.PARAMS = {
            size: 1,
            offset: {
                x: -3.5, 
                y: -2
            },
            rotation: 0.1
        }

        this.station = { 
            x: this.PARAMS.x, 
            y: this.PARAMS.y + 2.5 
        }

        this.setWorkshop()
        this.setDebug()
    }

    setWorkshop()
    {
        this.threeGroup = new THREE.Group()
        this.threeGroup.name = 'workshop'
        
        this.workshopModel.forEach((mesh) => {
            this.meshes[mesh.name] = mesh
            mesh.material = new THREE.MeshMatcapMaterial({
                matcap: this.textures[mesh.name],
                side: THREE.DoubleSide
            })
            this.threeGroup.add(mesh)
        })

        this.threeGroup.scale.setScalar(this.PARAMS.size)
        this.threeGroup.position.set(
            this.position.x + this.PARAMS.offset.x,
            0,
            this.position.y + this.PARAMS.offset.y
        )
        this.threeGroup.rotation.y = Math.PI * this.PARAMS.rotation

        this.scene.add(this.threeGroup)
    }

    setDebug()
    {
        if (this.debugFolder) {
            const size = this.debugFolder.addBinding(this.PARAMS, 'size', {
                min: 0,
                max: 1,
                step: 0.05
            })
            size.on('change', (ev) => {
                this.threeGroup.scale.setScalar(ev.value)
            })
    
    
            const offset = this.debugFolder.addBinding(this.PARAMS, 'offset', {
                // expanded: true
                x: { min: -10, max: 10 },
                y: { min: -10, max: 10 },
            })
            offset.on('change', (ev) => {
                this.threeGroup.position.set(
                    this.position.x + ev.value.x, 
                    0, 
                    this.position.y + ev.value.y
                )
            })
        }
        
    }
}
