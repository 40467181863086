import * as THREE from 'three'
import gsap from 'gsap'

import Experience from '../Experience'


export default class Raycaster
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.world = this.experience.world
        this.sueyoungshim = this.world.sueyoungshim

        this.enlarged = new Set()

        this.setRaycaster()
    }

    setRaycaster()
    {
        this.raycaster = new THREE.Raycaster()
        this.pointer = new THREE.Vector2()
        
        this.raycaster.setFromCamera( this.pointer, this.camera.instance )

        window.addEventListener( 'pointermove', (event) => {
            this.onPointerMove(event)
        }) 

        window.addEventListener( 'click', (event) => {
            this.onPointerDown(event)
        })
    }

    onPointerMove(event)
    {
        this.pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1
        this.pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1

        this.raycaster.setFromCamera( this.pointer, this.camera.instance )

        const intersects = this.raycaster.intersectObjects( this.scene.children ) // array

        // Set scale = 1 to non intersecting objects
        if (intersects.length == 0) {
            this.enlarged.forEach((mesh, i) => {
                mesh.scale.setScalar(1)

                this.enlarged.delete(mesh)
            })
        }

        // Set scale = 1.1 to intersecting objects
        for ( let i = 0; i < intersects.length; i ++ ) 
        {
            const object = intersects[i].object
            
            let group = object.parent

 
            if (group.name == 'whatsnext' && object.name.includes('BB')) 
            {
                group = object
            }

            if (group.name == 'halloween' || group.name == 'workshop' || object.name.includes('BB')) 
            {
                group.children.forEach((mesh) => 
                {
                    mesh.scale.setScalar(1.1)
    
                    this.enlarged.add(mesh)
                })
            }
        }
    }

    onPointerDown(event)
    {
        if (this.enlarged.size > 0) 
        {
            const group = this.enlarged.values().next().value.parent
            
            if (group.name == 'halloween' || group.name == 'workshop')
            {
                this.openLink(group.name)
            }

            if (group.name.includes('BB'))
            {
                this.openLink(group.name)
            }
        }
    }

    moveSueyoungshim(station)
    {
        this.world.currentIndex = this.world.stationsArr.indexOf(station)
        if (station.includes('BB')) return 
        const t = {
            'intro': 0.12,
            'berkeley': 0.3,
            'swiift': 0.4,
            'halloween': 0.5,
            'domino': 0.6,
            'workshop': 0.75,
            'whatsnext': 0.85,
        }
        this.sueyoungshim.gsapTo(t[station])
    }

    moveCamera(station)
    {
        const cameraOffset = 
        { 
            'berkeley': { x: 17.5, y: 5, z: 12.5 },
            'swiift': { x: 0, y: 0, z: 0 },
            'halloween': { x: 2, y: 3, z: 15 },
            'domino': { x: 3, y: 1, z: 10 },
            'construction': { x: 3, y: 1, z: 15 },
            'workshop': { x: 3, y: 2, z: 15 },
            'whatsnext': { x: 0, y: -2, z: 15 },
        }

        const group = this.world[station].threeGroup

        // get the target (look at) position
        const target = new THREE.Vector3()
        const bb = new THREE.Box3().setFromObject(group)
        bb.getCenter(target)

        gsap.to(this.camera.instance.position, 
            {
                x: group.position.x + cameraOffset[station].x,
                y: group.position.y + cameraOffset[station].y,
                z: group.position.z + cameraOffset[station].z,
                duration: 2,
                ease: 'power1.out',
                onUpdate: () => {
                    this.camera.controls.target = target
                },
            }
        )  
    }

    openLink(station)
    {
        if (station == 'domino') return
        const links = {
            'halloween': 'https://halloween-playground.vercel.app',
            // 'domino': '',
            'workshop': 'https://santas-workshop.vercel.app',
            'githubBB': 'https://github.com/sueyoungshim',
            'gmailBB': '',
            'linkedinBB': 'http://www.linkedin.com/in/sueyoungshim',
            'twitterBB': 'https://x.com/sueyoungshim',
        }

        window.open(links[station], '_blank')
    }
}
