import * as THREE from 'three'
import gsap from 'gsap'
import { WiggleBone } from "wiggle/spring"

import { TransformControls } from "three/examples/jsm/controls/TransformControls"
import { WiggleRigHelper } from "wiggle/helper"

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import Experience from '../Experience'

export default class Sueyoungshim {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.world = this.experience.world
        this.debug = this.experience.debug
        this.font = this.resources.font
        this.camera = this.experience.camera
        this.textures = this.resources.textures

        this.railway = this.world.railway

        this.models = this.resources.models.sueyoungshim

        this.wiggleBones = []
        this.letters = [] 
        this.PARAMS = { 
            t: 0,
            font: {
                size: 1,
                depth: 0.5,
                curveSegments: 0,
                bevelEnabled: false,
            } 
        }

        this.prevPosition = this.railway.path.getPointAt(this.PARAMS.t)

        this.setMesh()
        this.setDebug()
    }

    // setMesh() {
    //     const textMaterial = new THREE.MeshMatcapMaterial({
    //         matcap: this.textures['sueyoungshim'],
    //         color: '#c1dff7'
    //     })
    
    //     console.log(this.models)
        
    //     this.models.forEach((meshBone) => {
    //         // console.log(meshBone)
            
    //         const mesh = meshBone.children[0] // The letter mesh
    //         const rootBone = meshBone.children[1] // The root bone of the letter

    //         // console.log(mesh, rootBone)
            
    
    //         mesh.material = textMaterial
    //         // Add wiggle bones for the armature of each letter
    //         const wiggleBones = []
    //         const stiffness = 1000 // Adjust stiffness for the wiggle effect
    //         const damping = 100 // Adjust damping for smoothness
    
    //         // Assuming rootBone has children that need wiggle effects
    //         rootBone.traverse((bone) => {
    //             if (bone.isBone) {
    //                 const wb = new WiggleBone(bone, { stiffness, damping })
    //                 wiggleBones.push(wb)
    //                 this.wiggleBones.push(wb)
    //             }
    //         })

    //         console.log(rootBone)
            
    //         // rootBone.position.x -= 15
    //         // rootBone.rotation.x = Math.PI
    //         // rootBone.rotation.y = Math.PI

    //         const control = new TransformControls(this.camera.instance, this.experience.canvas)
    //         control.attach(rootBone)
    //         this.scene.add(control)
    
    //         this.letters.push({ mesh, rootBone, wiggleBones }) // Store mesh, rootBone, and wiggleBones for each letter
    //         this.scene.add(mesh)
    //     })

    //     this.moveMesh(this.PARAMS.t)
    //     this.gsapTo(0.15)
    // }
    

    setMesh() {
        const text = 'sueyoungshim'
        const textMaterial = new THREE.MeshMatcapMaterial({
            matcap: this.textures['sueyoungshim'],
            color: '#c1dff7'
        })

        for (let i = 0; i < text.length; i++) {
            const char = text[i]
            const charGeometry = new TextGeometry(char, {
                font: this.font,
                size: 1,
                depth: 1,
                curveSegments: 8,
                bevelEnabled: false,
            })
            charGeometry.center()

            const charMesh = new THREE.Mesh(charGeometry, textMaterial)

            this.scene.add(charMesh)
            this.letters.push(charMesh)
        }
        this.moveMesh(this.PARAMS.t)
        this.gsapTo(0.15)
     }

    moveMesh(t) {
        const path = this.railway.path
        
        if (this.letters.length > 0) {
            const letterSpacing = 0.007


            if (t > 1) t = 0

            this.letters.forEach((letter, index) => {
                const charT = (t + index * letterSpacing) % 1
                
                const point = path.getPointAt(charT)
                const tangent = path.getTangentAt(charT).normalize()
                const angle = Math.atan2(tangent.z, -tangent.x) + Math.PI

                letter.position.set(point.x, point.y + 0.5, point.z)
                letter.rotation.y = angle

                // letter.rootBone.position.set(point.x, point.y + 0.5, point.z)
                // letter.rootBone.rotation.y = angle
            })
        }
    }

    gsapTo(t)
    {
        gsap.to(this.PARAMS, {
            't': t,
            duration: 2,
            ease: 'power4.out',
            onUpdate: () => {
                this.moveMesh(this.PARAMS.t)
            }
        })
    }

    setDebug()
    {
        if (!this.debug.active) return
        
        this.debugFolder = this.debug.ui.addFolder({
            title: 'sueyoungshim',
            expanded: false
        })

        const t = this.debugFolder.addBinding(this.PARAMS, 't', {
            min: 0,
            max: 1
        })
        t.on('change', (ev) => {
            this.moveMesh(ev.value)
        })

        const gsap = this.debugFolder.addButton({
            title: 'gsap to t = 1'
        })
        gsap.on('click', () => {
            this.gsapTo(1)
        })
    }

    update()
    {
        if (!this.wiggleBones) return
        this.wiggleBones.forEach((wb) => {
            // wb.update()
        })
        if (!this.railway) return 

        // return

        const point = this.railway.path.getPointAt(this.PARAMS.t)

        const currentX = point.x
        const currentZ = point.z

        const deltaX = currentX - this.prevPosition.x
        const deltaZ = currentZ - this.prevPosition.z

        this.prevPosition.x = currentX
        this.prevPosition.z = currentZ

        const smoothFactor = 0.2

        this.camera.instance.position.x += deltaX * smoothFactor
        this.camera.instance.position.z += deltaZ * smoothFactor

        this.camera.controls.target.x += deltaX * smoothFactor
        this.camera.controls.target.z += deltaZ * smoothFactor
    }

    // update()
    // {
    //     if (!this.railway) return 

    //     const point = this.railway.path.getPointAt(this.PARAMS.t)
        
    //     const current = point.clone()

    //     const delta = current.sub(this.prevPosition)

    //     this.prevPosition = current.clone()

    //     this.camera.instance.position.add(delta.multiplyScalar(0.1))
    //     this.camera.controls.target.add(delta.multiplyScalar(0.1))

    // }
}
