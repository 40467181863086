import * as THREE from 'three'

import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js"
import Experience from "../Experience"

export default class Text
{
    constructor()
    {
        this.experience = new Experience()
        this.font = this.experience.resources.font
    }

    /**
     * Creates a text mesh with the specified properties.
     * 
     * @param {Object} obj - Configuration object for the text mesh.
     * @param {string} obj.text - The text string to render.
     * @param {number} obj.size - The size of the text.
     * @param {number} obj.depth - The depth of the text (extrusion).
     * @param {THREE.Material} obj.material - The material for the text mesh.
     * @returns {THREE.Mesh} The created text mesh.
     */
    createTextMesh(obj) 
    {
        const geometry = new TextGeometry(obj.text, {
            font: this.font,
            size: obj.size,
            depth: obj.depth,
            curveSegments: 8,
            bevelEnabled: false,
        })

        const textMesh = new THREE.Mesh(geometry, obj.material)
        return textMesh
    }
}
