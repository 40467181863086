import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        
        

        this.PARAMS = {
            background: {r: 170, g: 170, b: 170}
        }

        this.setColor()

        this.setDebug()

        // this.setSunLight()
        // this.setEnvironmentMap()
    }

    setColor()
    {
        this.scene.background = new THREE.Color(this.getRGB(this.PARAMS.background))
    }

    getRGB(rgb)
    {
        return `rgb(${Math.floor(rgb.r)}, ${Math.floor(rgb.g)}, ${Math.floor(rgb.b)})`
    }


    setDebug()
    { 
        if (!this.debug.active) return

        this.debugFolder = this.debug.ui.addFolder({
            title: 'environment',
            expanded: false
        })
        
        const color = this.debugFolder.addBinding(this.PARAMS, 'background')
        color.on('change', (ev) => {
            const value = this.getRGB(ev.value)
            this.scene.background = new THREE.Color(value)
        })
    }
}
