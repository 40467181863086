import * as THREE from 'three'
import gsap from 'gsap'

import Experience from "../../Experience"

import colors from '../../colors.js'

export default class WhatsNext
{
    constructor()
    {
        this.experience = new Experience()
        this.world = this.experience.world
        this.debug = this.experience.debug
        this.resources = this.experience.resources
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera
        this.text = this.world.text
        this.scene = this.world.scene

        // Resources
        this.contactModels = this.resources.models.whatsnext
        this.textures = this.resources.textures

        this.position = this.world.stations.whatsnext
        this.focused = false

        this.PARAMS = {
            size: 1,
            offset: {
                x: 10, 
                y: 0
            },
            rotation: 0
        }

        this.meshes = []

        this.setGroup()
        this.setText()
        this.setModels()
        this.setPlaceHolder()
        this.setDebug()
        this.update()
    }

    setGroup()
    {
        this.threeGroup = new THREE.Group()
        this.threeGroup.name = 'whatsnext'
        this.scene.add(this.threeGroup)
    }

    setModels()
    {
        this.contactModels.forEach((boundingBox) => {
            boundingBox.children.forEach((mesh) => {
                mesh.material = new THREE.MeshMatcapMaterial({
                    color: colors[mesh.name]
                })
            })
            boundingBox.material.transparent = true
            boundingBox.material.opacity = 0

            boundingBox.position.y -= 3
            
            this.meshes.push(boundingBox)
            this.threeGroup.add(boundingBox)
        })
    }



    setPlaceHolder()
    {
        const cube = new THREE.Mesh(
            new THREE.BoxGeometry(1, 1, 1),
            new THREE.MeshBasicMaterial({
                transparent: true,
                opacity: 0.5
            })
        )
        cube.position.set(0, -5, 0)
        cube.visible = false
        this.threeGroup.add(cube)
    }

    setText()
    {
        const textMaterial = new THREE.MeshMatcapMaterial({ 
            // matcap: this.textures.
        })
        this.mesh = this.text.createTextMesh({
            text: "What's Next?",
            size: this.PARAMS.size,
            depth: 0.1,
            material: textMaterial
        })
        this.mesh.name = 'whatsnext'
        this.mesh.geometry.center()

        this.threeGroup.add(this.mesh)

        this.threeGroup.position.set(
            this.position.x + this.PARAMS.offset.x, 
            5, 
            this.position.y + this.PARAMS.offset.y
        )
        this.threeGroup.rotation.y = Math.PI * this.PARAMS.rotation
    }

    setDebug()
    {
        if (!this.debug.active) return

        this.debugFolder = this.debug.ui.addFolder({
            title: "What's next?",
            expanded: false
        })

        const size = this.debugFolder.addBinding(this.PARAMS, 'size', {
            min: 0,
            max: 1,
            step: 0.05
        })
        size.on('change', (ev) => {
            this.threeGroup.scale.setScalar(ev.value)
        })


        const offset = this.debugFolder.addBinding(this.PARAMS, 'offset', {
            // expanded: true
            x: { min: -10, max: 10 },
            y: { min: -10, max: 10 },
        })
        offset.on('change', (ev) => {
            this.threeGroup.position.set(
                this.position.x + ev.value.x, 
                0, 
                this.position.y + ev.value.y
            )
        })

        const rotation = this.debugFolder.addBinding(this.PARAMS, 'rotation', {
            min: 0,
            max: 2,
            step: 0.05
        })
        rotation.on('change', (ev) => {
            this.threeGroup.rotation.y = Math.PI * ev.value
        })
    }

    setCamera()
    {
        gsap.to(this.camera.instance.position, 
            {
                x: this.threeGroup.position.x,
                y: this.threeGroup.position.y - 2,
                z: this.threeGroup.position.z + 20,
                duration: 2,
                ease: 'power1.out',
            }
        ) 

        gsap.to(this.camera.controls.target, 
            {
                x: this.threeGroup.position.x,
                y: this.threeGroup.position.y - 2,
                z: this.threeGroup.position.z, 
                duration: 2,
                ease: 'power1.out',
            }
        ) 
    }

    update()
    {
        // if (!this.meshes.length != 4) return

        // this.meshes.forEach((mesh, i) => {
        //     mesh.position.y = 
        // })

        // window.addEventListener('pointermove', (ev) => {
        //     const x = (ev.clientX / this.sizes.width - 0.5) * 0.5
        //     const y = (ev.clientY / this.sizes.height - 0.5) * 0.5

        //     this.meshes.forEach((mesh, i) => {
        //         mesh.position.x += (x - mesh.position.x) * 0.02
        //         mesh.position.y -= (y - mesh.position.x) * 0.02

        //         mesh.rotation.z -= (x - mesh.position.x) * 0.02
        //         mesh.rotation.x += (y - mesh.position.x) * 0.02
        //     })
        // })
    }
}
