import * as THREE from 'three'
import gsap from 'gsap'
import { WiggleBone } from "wiggle/spring"

import { TransformControls } from "three/examples/jsm/controls/TransformControls"
import { WiggleRigHelper } from "wiggle/helper"

import Experience from '../../Experience.js'

export default class Halloween
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        this.world = this.experience.world

        

        // Resource
        this.halloweenModel = this.resources.models.halloween
        this.textures = this.resources.textures
        
        this.position = this.world.stations.halloween
        this.focused = false

        this.wiggleBones = []
        this.meshes = {}

        this.PARAMS = {
            size: 0.4,
            offset: {
                x: 4, 
                y: 4
            },
            rotation: 0.85,
            ghostPositionXY: {
                x: 5,
                y: 2
            },
            ghostPositionZ: 0
        }

        this.station = {
            x: this.PARAMS.x,
            y: this.PARAMS.y -2
        }

        this.setSlide()
        this.setPointer()
        this.setDebug()
    }

    setSlide()
    {
        this.threeGroup = new THREE.Group()
        this.threeGroup.name = 'halloween'

        const slide = this.halloweenModel[0]
        slide.material = new THREE.MeshMatcapMaterial({ 
            matcap: this.textures['slide']
        })
        this.meshes['slide'] = slide
        this.threeGroup.add(slide)

        this.threeGroup.scale.setScalar(this.PARAMS.size)
        this.threeGroup.position.set(
            this.position.x + this.PARAMS.offset.x, 
            0, 
            this.position.y + this.PARAMS.offset.y
        )
        this.threeGroup.rotation.y = this.PARAMS.rotation * Math.PI

        this.scene.add(this.threeGroup)

        this.setGhost()
    }

    setGhost()
    {
        const ghost = this.halloweenModel[1]
        const ghostMesh = ghost.children[0]
        const rootBone = ghost.children[1]
        const b1 = rootBone.children[0]
        const b2 = b1.children[0]
        const b3 = b2.children[0]

        this.rootBone = rootBone
    
        this.wiggleBones.push(new WiggleBone(b1, { stiffness: 1000, damping: 100 }))
        this.wiggleBones.push(new WiggleBone(b2, { stiffness: 1000, damping: 100 }))
        this.wiggleBones.push(new WiggleBone(b3, { stiffness: 1000, damping: 100 }))

        ghostMesh.material = new THREE.MeshMatcapMaterial({
            transparent: true,
            opacity: 0.5,
            side: THREE.DoubleSide
        })
        ghostMesh.scale.setScalar(1)
        ghostMesh.position.set(this.PARAMS.ghostPositionXY.x, this.PARAMS.ghostPositionXY.y, this.PARAMS.ghostPositionZ)
        this.meshes['ghost'] = ghostMesh

        this.threeGroup.add(ghost)

        this.animateGhost()
    }

    setDebug()
    {
        if (!this.debug.active) return
        this.debugFolder = this.debug.ui.addFolder({
            title: 'halloween',
            expanded: false
        })

        const size = this.debugFolder.addBinding(this.PARAMS, 'size', {
            min: 0,
            max: 1,
            step: 0.05
        })
        size.on('change', (ev) => {
            this.threeGroup.scale.setScalar(ev.value)
        })


        const offset = this.debugFolder.addBinding(this.PARAMS, 'offset', {
            // expanded: true
            x: { min: -10, max: 10 },
            y: { min: -10, max: 10 },
        })
        offset.on('change', (ev) => {
            this.threeGroup.position.set(
                this.position.x + ev.value.x, 
                0, 
                this.position.y + ev.value.y
            )
        })

        const rotation = this.debugFolder.addBinding(this.PARAMS, 'rotation', {
            min: 0,
            max: 2,
            step: 0.05
        })
        rotation.on('change', (ev) => {
            this.threeGroup.rotation.y = Math.PI * ev.value
        })

        // const ghostPositionXY = this.debugFolder.addBinding(this.PARAMS, 'ghostPositionXY', {
        //     // expanded: true
        //     x: { min: -10, max: 10 },
        //     y: { min: -10, max: 10 },
        // })
        // ghostPositionXY.on('change', (ev) => {
        //     this.rootBone.position.x = ev.value.x
        //     this.rootBone.position.y = ev.value.y
        // })

        // const ghostPositionZ = this.debugFolder.addBinding(this.PARAMS, 'ghostPositionZ', {
        //     min: -10,
        //     max: 10,
        //     step: 0.05,
        // })
        // ghostPositionZ.on('change', (ev) => {
        //     this.rootBone.position.z = ev.value
        // })
    }

    setPointer()
    {
        const pointer = this.resources.models.pointer[0]
        pointer.material = new THREE.MeshMatcapMaterial({
            
        })
        pointer.rotation.y = Math.PI
        this.threeGroup.add(pointer)
    }

    animateGhost()
    {
        const keyFrames = {
            '0%': { x: 6, y: 3, z: 0 },
            '15%': { x: 3.5, y: 7, z: 0 },
            '40%': { x: -6, y: 3, z: 0 },
            '60%': { x: -6, y: 3, z: -4 },
            '85%': { x: 6, y: 3, z: -4 },
            '100%': { x: 6, y: 3, z: 0 },
        }
        gsap.to(this.rootBone.position, {
            keyframes: keyFrames,
            duration: 10,
            // ease: 'power1.out',
            onComplete: () => {
                this.animateGhost()
            }
        })
    }

    update()
    {
        if (this.wiggleBones) {
            this.wiggleBones.forEach((wb) => wb.update())
        }
    }
}
