import * as THREE from 'three'
import gsap from 'gsap'

import Experience from "../../Experience"

export default class SWiiFT
{
    constructor()
    {
        this.experience = new Experience()
        this.world = this.experience.world
        this.resources = this.experience.resources
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera
        this.scene = this.world.scene
        this.text = this.world.text

        this.model = this.resources.models.swiift
        this.textures = this.resources.textures

        this.position = this.world.stations.swiift
        this.focused = false

        this.meshes = {}
        this.PARAMS = {
            size: 1,
            offset: 
            {
                x: 3, 
                y: -2.5
            },
            planePosition: 
            { 
                x: -3.2, 
                y: 3.3, 
                z: -1.5 
            },
            planeRotation: 
            { 
                x: 0.32, 
                y: 1.10, 
                z: -0.15 
            },
            textPosition: 
            {
                x: 2, 
                y: 5
            }
        }

        this.setModel()
        this.setText()
        this.displayText()
        this.setDebug()

    }

    setModel()
    {
        this.threeGroup = new THREE.Group()
        this.scene.add(this.threeGroup)

        const suitcase = this.model[0]
        const plane = this.model[1]

        suitcase.material = new THREE.MeshMatcapMaterial({
            matcap: this.textures.slide,
            color: '#ffe793'
        })
        plane.material = new THREE.MeshMatcapMaterial({
            color: '#eaeaea'
        })

        plane.rotation.set(
            this.PARAMS.planeRotation.x,
            this.PARAMS.planeRotation.y,
            this.PARAMS.planeRotation.z
        )
        plane.position.set(
            this.PARAMS.planePosition.x,
            this.PARAMS.planePosition.y,
            this.PARAMS.planePosition.z
        )
        plane.scale.setScalar(1.5)

        this.threeGroup.scale.setScalar(this.PARAMS.size)
        this.threeGroup.position.set(
            this.position.x + this.PARAMS.offset.x, 
            0, 
            this.position.y + this.PARAMS.offset.y
        )
        this.threeGroup.add(suitcase, plane)

        this.plane = plane 
        this.animatePlane()
    }

    animatePlane()
    {
        window.addEventListener('pointermove', (ev) => {
            const x = (ev.clientX / this.sizes.width - 0.5) * 0.5
            const y = (ev.clientY / this.sizes.height - 0.5) * 0.5

            this.plane.position.x += ((this.PARAMS.planePosition.x + x) - this.plane.position.x) * 0.2
            this.plane.position.y += ((this.PARAMS.planePosition.y + y) - this.plane.position.y) * 0.2
        
            this.plane.rotation.x += ((this.PARAMS.planeRotation.x + x) - this.plane.rotation.x) * 0.05
            this.plane.rotation.y += ((this.PARAMS.planeRotation.y + y) - this.plane.rotation.y) * 0.05
        })
    }

    setText()
    {
        this.textMeshes = []

        const textMaterial = new THREE.MeshMatcapMaterial({
            matcap: this.textures.window,
            transparent: true,
            opacity: 0
        })

        this.texts = [
            { 
                text: 'Software Engineer Intern', 
                size: 1, 
                depth: 0.2,
                material: textMaterial,
                yOffset: 0 
            },
            { 
                text: 'SWiiFT', 
                size: 0.6, 
                depth: 0.2,
                material: textMaterial,
                yOffset: -1 
            },
            { 
                text: '2023-2024', 
                size: 0.4, 
                depth: 0.2,
                material: textMaterial,
                yOffset: -1.75 
            }
        ]

        this.texts.forEach((textObj) => {
            const textMesh = this.text.createTextMesh(textObj)
            textMesh.position.x = this.PARAMS.textPosition.x + 1
            textMesh.position.y = this.PARAMS.textPosition.y + textObj.yOffset
            
            this.threeGroup.add(textMesh)
            this.textMeshes.push(textMesh)
        })
    }

    displayText()
    {
        this.textMeshes.forEach((textMesh) => {
            gsap.to(textMesh.material, {
                opacity: 1,
                duration: 1, 
                onUpdate: () => {
                    textMesh.lookAt(this.camera.instance.position)
                }
            })
        })
    }

    hideText()
    {
        this.textMeshes.forEach((textMesh) => {
            gsap.to(textMesh.material, {
                opacity: 0,
                duration: 1, 
                onUpdate: () => {
                    textMesh.lookAt(this.camera.instance.position)
                }
            })
        })
    }

    update()
    {
        if (this.focused) this.displayText()
        if (!this.focused) this.hideText()
    }

    setDebug()
    {
        if (!this.experience.debug.active) return

        this.debugFolder = this.experience.debug.ui.addFolder({
            title: 'SWiiFT Plane',
            expanded: false
        })

        this.debugFolder.addBinding(this.PARAMS.planePosition, 'x', { min: -10, max: 10, step: 0.1 })
            .on('change', () => {
                this.plane.position.x = this.PARAMS.planePosition.x
            })
        this.debugFolder.addBinding(this.PARAMS.planePosition, 'y', { min: -10, max: 10, step: 0.1 })
            .on('change', () => {
                this.plane.position.y = this.PARAMS.planePosition.y
            })
        this.debugFolder.addBinding(this.PARAMS.planePosition, 'z', { min: -10, max: 10, step: 0.1 })
            .on('change', () => {
                this.plane.position.z = this.PARAMS.planePosition.z
            })

        this.debugFolder.addBinding(this.PARAMS.planeRotation, 'x', { min: -Math.PI, max: Math.PI, step: 0.01 })
            .on('change', () => {
                this.plane.rotation.x = this.PARAMS.planeRotation.x
            })
        this.debugFolder.addBinding(this.PARAMS.planeRotation, 'y', { min: -Math.PI, max: Math.PI, step: 0.01 })
            .on('change', () => {
                this.plane.rotation.y = this.PARAMS.planeRotation.y
            })
        this.debugFolder.addBinding(this.PARAMS.planeRotation, 'z', { min: -Math.PI, max: Math.PI, step: 0.01 })
            .on('change', () => {
                this.plane.rotation.z = this.PARAMS.planeRotation.z
            })
    }
}
