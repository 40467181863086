export default [
    {
        name: 'campanile',
        type: 'gltfModel',
        path: 'models/campanile.glb'
    },
    {
        name: 'campanile',
        type: 'texture',
        path: 'textures/matcaps/campanile.png',
    },
    {
        name: 'campanile-roof',
        type: 'texture',
        path: 'textures/matcaps/campanileRoof.png'
    },
    ////
    {
        name: 'workshop',
        type: 'gltfModel',
        path: 'models/workshop.glb'
    },
    {
        name: 'wall',
        type: 'texture',
        path: 'textures/matcaps/wall.png',

    },
    {
        name: 'snow',
        type: 'texture',
        path: 'textures/matcaps/snow.png',
    },
    {
        name: 'wood',
        type: 'texture',
        path: 'textures/matcaps/wood.png',
    },
    {
        name: 'rocks',
        type: 'texture',
        path: 'textures/matcaps/rock.png',
    },
    {
        name: 'window',
        type: 'texture',
        path: 'textures/matcaps/window.png',
    },
    ///
    {
        name: 'halloween',
        type: 'gltfModel',
        path: 'models/halloween.glb'
    },
    {
        name: 'slide',
        type: 'texture',
        path: 'textures/matcaps/slide.png'
    },
    // {
    //     name: 'ghost',
    //     type: 'gltfModel',
    //     path: 'models/ghost.glb'
    // },
    ///
    {
        name: 'magnum',
        type: 'font',
        path: 'fonts/magnum.json'
    },
    {
        name: 'construction',
        type: 'gltfModel',
        path: 'models/construction.glb'
    },
    {
        name: 'whatsnext',
        type: 'gltfModel',
        path: 'models/whatsnext.glb'
    },
    {
        name: 'orangeCone',
        type: 'texture',
        path: 'textures/matcaps/orangeCone.png'
    },
    {
        name: 'sueyoungshim',
        type: 'texture',
        path: 'textures/matcaps/sueyoungshim.png'
    },
    {
        name: 'linkedin',
        type: 'texture',
        path: 'textures/matcaps/linkedin.png'
    },
    // {
    //     name: 'sueyoungshim',
    //     type: 'gltfModel',
    //     path: 'models/sueyoungshimText.glb'
    // },
    {
        name: 'swiift',
        type: 'gltfModel',
        path: 'models/swiift.glb'
    },
    {
        name: 'pointer',
        type: 'gltfModel',
        path: 'models/pointer.glb'
    },
]
