import * as THREE from 'three'
import gsap from 'gsap'

import { MapControls } from 'three/examples/jsm/controls/MapControls.js'

import Experience from './Experience.js'


export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        this.origin = new THREE.Vector3(8, 9, 27)
        this.target = new THREE.Vector3(-13, -3, -10.7)
        // this.startPosition = new THREE.Vector3(-27.5, 0.5, 15)
        // this.startTarget = new THREE.Vector3(-26, 0.5, 0)

        this.startPosition = new THREE.Vector3(-20, 4.5, 20)
        this.startTarget = new THREE.Vector3(-31, 0.5, -2)

        this.setInstance()
        this.setControls()
        this.setStart()
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 100)
        // this.instance.position.set(this.origin.x, this.origin.y, this.origin.z)
        
        this.scene.add(this.instance)
    }

    setStart()
    {
        this.instance.position.set(this.startPosition.x, this.startPosition.y, this.startPosition.z)
        this.controls.target = this.startTarget
    }

    setControls()
    {
        // this.controls = new OrbitControls(this.instance, this.canvas)

        this.controls = new MapControls(this.instance, this.canvas)
        this.controls.mouseButtons = {
            LEFT: THREE.MOUSE.PAN,
            MIDDLE: THREE.MOUSE.DOLLY,
            RIGHT: THREE.MOUSE.ROTATE
        }
        this.controls.touches = {
            ONE: THREE.TOUCH.PAN,
            TWO: THREE.TOUCH.DOLLY_ROTATE
        }
        this.controls.target = this.target
        // this.controls.minDistance = 5
        // this.controls.maxDistance = this.target.distanceTo(this.origin)
        // this.controls.panSpeed = 0.5
        // this.controls.maxTargetRadius = 50
        this.controls.rotateSpeed = 0.2
        this.controls.enableZoom = false
        this.controls.enableDamping = true
    }

    setBirdsEyeView()
    {
        gsap.to(this.instance.position, 
            {
                x: this.origin.x,
                y: this.origin.y,
                z: this.origin.z,
                duration: 2,
                ease: 'power1.out',
                onUpdate: () => {
                    this.controls.target = this.target
                },
            }
        )    
    } 

    showWhatsnext()
    {
        gsap.to(this.instance.position, 
            {
                x: 10,
                y: 10,
                z: 10,
                duration: 2,
                ease: 'power1.out',
                onUpdate: () => {
                    this.controls.target = this.target
                },
            }
        ) 

        gsap.to(this.controls.target, 
            {
                x: 17,
                y: 5,
                z: 5, 
                duration: 2,
                ease: 'power1.out',
                onUpdate: () => {
                    this.controls.target = this.target
                },
            }
        ) 
    }

    panTo(t)
    {
        // const prevT
        // const currT

        
        // const destination 

        // const currentPosition = this.instance.position.clone()
        // const currentTarget = this.controls.target.clone()

        // // const offset = this.instance.position.subVectors(destination)
        // const offset = destination.subVectors(currentPosition)

        // this.instance.position.set(currentPosition.add(offset))
        // this.controls.target = currentTarget.add(offset)

    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
         this.controls.update()
    }
}
