import * as THREE from 'three'

import Experience from "../Experience"

export default class Railway {
    constructor() {
        this.experience = new Experience()
        this.debug = this.experience.debug
        this.scene = this.experience.scene
        this.world = this.experience.world

        // STATIONS
        this.berkeley = this.world.berkeley
        this.halloween = this.world.halloween
        this.workshop = this.world.workshop

        this.PARAMS = {
            width: 1,
            height: 0.2,
            sleeperColor: {r: 120, g: 120, b: 120},
            railColor: {r: 48, g: 48, b: 48},
        }

        this.setRailway()
        this.setDebug()
    }

    setRailway() {
        this.path = this.createCurvePath()
        this.length = this.path.getLength()

        const numSleepers = Math.floor(this.length * 2)
        const railPositions = this.getRailPositions(numSleepers)

        this.createSleepers(numSleepers)
        this.createRails(railPositions)
    }

    createCurvePath() {
        const stations = Object.values(this.world.stations)
        
        const points = stations.map(station => new THREE.Vector3(station.x, 0, station.y))
        return new THREE.CatmullRomCurve3(points)
    }

    getRailPositions(numSleepers) {
        const rail1Position = []
        const rail2Position = []

        for (let i = 0; i <= numSleepers; i++) {
            const t = i / numSleepers
            const point = this.path.getPointAt(t)

            const railOffset = this.PARAMS.width / 3
            const normal = this.getNormal(t)

            const point1 = this.getOffsetPoint(point, normal, railOffset)
            const point2 = this.getOffsetPoint(point, normal, -railOffset)

            rail1Position.push(point1)
            rail2Position.push(point2)
        }
        return { rail1Position, rail2Position }
    }

    getRGB(rgb)
    {
        return `rgb(${Math.floor(rgb.r)}, ${Math.floor(rgb.g)}, ${Math.floor(rgb.b)})`
    }

    getNormal(t) {
        const up = new THREE.Vector3(0, 1, 0)
        const tangent = this.path.getTangentAt(t).normalize()
        return new THREE.Vector3().crossVectors(tangent, up).normalize()
    }

    getOffsetPoint(point, normal, offset) {
        return point.clone().add(normal.clone().multiplyScalar(offset))
    }

    createSleepers(numSleepers) {
        this.sleepers = new THREE.Group()

        // const geometry = new THREE.PlaneGeometry(this.PARAMS.height, this.PARAMS.width)
        const geometry = new THREE.BoxGeometry(this.PARAMS.height, this.path.width, 0.05)
        const material = new THREE.MeshMatcapMaterial({
            color: this.getRGB(this.PARAMS.sleeperColor),
            side: THREE.DoubleSide
        })
        this.sleeperMaterial = material

        for (let i = 0; i <= numSleepers; i++) {
            const t = i / numSleepers
            const point = this.path.getPointAt(t)

            const sleeper = new THREE.Mesh(geometry, material)
            sleeper.position.set(point.x, 0, point.z)
            sleeper.rotation.x = Math.PI * 0.5

            const tangent = this.path.getTangentAt(t).normalize()
            const angle = Math.atan2(tangent.z, tangent.x)

            sleeper.rotation.z = angle

            this.sleepers.add(sleeper)
            // this.scene.add(sleeper)
        }
        this.scene.add(this.sleepers)
    }

    createRails(railPositions) {
        const railMaterial = new THREE.LineBasicMaterial({ 
            color: this.getRGB(this.PARAMS.railColor),
            linewidth: 10 
        })
        this.railMaterial = railMaterial

        const rail1Geometry = new THREE.BufferGeometry().setFromPoints(railPositions.rail1Position)
        const rail2Geometry = new THREE.BufferGeometry().setFromPoints(railPositions.rail2Position)

        this.rail1 = new THREE.Line(rail1Geometry, railMaterial)
        this.rail2 = new THREE.Line(rail2Geometry, railMaterial)

        this.scene.add(this.rail1, this.rail2)
    }

    setDebug()
    {
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder({ 
                title: 'railway',
                // expanded: false
            })

            this.debugFolder.addBinding(this.PARAMS, 'sleeperColor').on('change', (ev) => {
                this.sleeperMaterial.color.set(this.getRGB(ev.value))
            })

            this.debugFolder.addBinding(this.PARAMS, 'railColor').on('change', (ev) => {
                this.railMaterial.color.set(this.getRGB(ev.value))
            })
        }
    }

    clear()
    {
        this.rail1.geometry.dispose()
        this.rail2.geometry.dispose()
        this.scene.remove(this.rail1, this.rail2, this.sleepers)
    }
}
