export default {
    "campanile" : "#D3C4AB",
    "campanile-roof" : "#9BB2A6",
    "wall" : "",
    "snow" : "",
    "wood" : "",
    "rocks" : "",
    "window" : "",
    "slide" : "",
    "orangeCone": "",
    "whiteCone": "",
    "linkedin": "#0076B3",
    "github": "#25292F",
    "twitter": "#000000",
    "gmailRed": "#BF211F",
    "gmailBlue": "#3F81ED",
    "gmailOrange": "#E34034",
    "gmailGreen": "#31A351",
    "gmailYellow": "#F2B607",


}
