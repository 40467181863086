import * as THREE from 'three'

import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'

import EventEmitter from './EventEmitter.js'

export default class Resources extends EventEmitter
{
    constructor(sources)
    {
        super()

        this.sources = sources

        this.models = {}
        this.textures = {}
        this.font = null
        this.toLoad = this.sources.length
        this.loaded = 0

        this.setLoaders()
        this.startLoading()
    }

    setLoaders()
    {
        this.loadingManager = new THREE.LoadingManager()
        this.loaders = {}
        this.loaders.dracoLoader = new DRACOLoader(this.loadingManager)
        this.loaders.dracoLoader.setDecoderPath('/draco/')
        this.loaders.gltfLoader = new GLTFLoader(this.loadingManager)
        this.loaders.gltfLoader.setDRACOLoader(this.loaders.dracoLoader)
        this.loaders.textureLoader = new THREE.TextureLoader(this.loadingManager)
        this.loaders.fontLoader = new FontLoader(this.loadingManager)
    }

    startLoading()
    {
        // Load each source
        for (const source of this.sources)
        {
            if (source.type === 'gltfModel')
            {
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.models[source.name] = [...file.scene.children]
                        
                        this.sourceLoaded(source, [...file.scene.children])
                    }
                )
            }
            else if (source.type === 'texture')
            {
                this.loaders.textureLoader.load(
                    source.path,
                    (file) =>
                    {
                        file.colorSpace = THREE.SRGBColorSpace
                        this.textures[source.name] = file

                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if (source.type === 'font')
            {
                this.loaders.fontLoader.load(
                    source.path,
                    (file) => 
                    {
                        this.font = file

                        this.sourceLoaded(source, file)
                    }
                )
            }
        }
    }

    sourceLoaded (source, file)
    {
        this.loaded++

        if (this.loaded === this.toLoad)
        {
            this.trigger('ready')
        }
    }
}
