import * as THREE from 'three'
import gsap from 'gsap'

import Experience from '../../Experience.js'
import colors from '../../colors.js'

export default class Berkeley
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        this.world = this.experience.world
        this.camera = this.experience.camera
        this.text = this.world.text

        // Resource
        this.campanileModel = this.resources.models.campanile
        this.textures = this.resources.textures

        this.position = this.world.stations.berkeley
        this.focused = false

        this.meshes = {}
        this.PARAMS = {
            size: 1,
            offset: {
                x: -2.5, 
                y: -2.5
            },
            textPosition: {
                x: 2, 
                y: 5
            }
        }

        this.setCampanile()
        this.setText()
        this.setDebug()
    }

    setCampanile()
    {
        this.threeGroup = new THREE.Group()
        this.threeGroup.name = 'berkeley'

        this.campanileModel.forEach((mesh) => {
            this.meshes[mesh.name] = mesh
            mesh.material = new THREE.MeshMatcapMaterial({
                matcap: this.textures[mesh.name],
                // color: colors[mesh.name]
            })
            this.threeGroup.add(mesh)
        })

        this.threeGroup.scale.setScalar(this.PARAMS.size)
        this.threeGroup.position.set(
            this.position.x + this.PARAMS.offset.x, 
            0, 
            this.position.y + this.PARAMS.offset.y
        )
        this.scene.add(this.threeGroup)
    }

    setText()
    {
        this.textMeshes = []

        const textMaterial = new THREE.MeshMatcapMaterial({
            matcap: this.textures.window,
            transparent: true,
            opacity: 0
        })

        this.texts = [
            { 
                text: 'UC Berkeley', 
                size: 1, 
                depth: 0.2,
                material: textMaterial,
                yOffset: 0 
            },
            { 
                text: 'computer science & math', 
                size: 0.6, 
                depth: 0.2,
                material: textMaterial,
                yOffset: -1 
            },
            { 
                text: '2020 - 2024', 
                size: 0.4, 
                depth: 0.2,
                material: textMaterial,
                yOffset: -1.75 
            }
        ]

        this.texts.forEach((textObj) => {
            const textMesh = this.text.createTextMesh(textObj)
            textMesh.position.x = this.PARAMS.textPosition.x + 1
            textMesh.position.y = this.PARAMS.textPosition.y + textObj.yOffset
            
            this.threeGroup.add(textMesh)
            this.textMeshes.push(textMesh)
        })
    }

    displayText()
    {
        this.textMeshes.forEach((textMesh) => {
            gsap.to(textMesh.material, {
                opacity: 1,
                duration: 1, 
                onUpdate: () => {
                    textMesh.lookAt(this.camera.instance.position)
                }
            })
        })
    }

    hideText()
    {
        this.textMeshes.forEach((textMesh) => {
            gsap.to(textMesh.material, {
                opacity: 0,
                duration: 1, 
                onUpdate: () => {
                    textMesh.lookAt(this.camera.instance.position)
                }
            })
        })
    }



    setDebug()
    {
        // Debug
        if (!this.debug.active) return
        this.debugFolder = this.debug.ui.addFolder({
            title: 'berkeley',
            expanded: false
        })

        const size = this.debugFolder.addBinding(this.PARAMS, 'size', {
            min: 0,
            max: 1,
            step: 0.05
        })
        size.on('change', (ev) => {
            this.threeGroup.scale.setScalar(ev.value)
        })


        const offset = this.debugFolder.addBinding(this.PARAMS, 'offset', {
            // expanded: true
            x: { min: -10, max: 10 },
            y: { min: -10, max: 10 },
        })
        offset.on('change', (ev) => {
            this.threeGroup.position.set(
                this.position.x + ev.value.x, 
                0, 
                this.position.y + ev.value.y
            )
        })

        const textPosition = this.debugFolder.addBinding(this.PARAMS, 'textPosition', {
            // expanded: true,
            x: { min: -5, max: 5 },
            y: { min: -5, max: 5 },
        })
        textPosition.on('change', (ev) => {
            this.textMeshes.forEach((text, i) => {
                text.position.set(
                    ev.value.x, 
                    ev.value.y + this.texts[i].yOffset,
                    0, 
                )
            })
        })
    }

    update()
    {
        if (this.focused) this.displayText()
        if (!this.focused) this.hideText()
    }
}
