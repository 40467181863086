import * as THREE from 'three'
import gsap from 'gsap'

import Experience from "../../Experience"

export default class Domino
{
    constructor()
    {
        this.experience = new Experience()
        this.time = this.experience.time
        this.world = this.experience.world
        this.debug = this.experience.debug
        this.scene = this.world.scene

        this.position = this.world.stations.domino
        this.focused = false

        this.dominoes = []
        this.PARAMS = {
            count: 7,
            width: 0.5,
            height: 1,
            depth: 0.2,
            offset: {
                x: 0, 
                y: 3
            },
            rotation: 0.4
        }

        this.setDominoes()
        this.triggerDominoFall()
        this.setDebug()

    }

    setDominoes()
    {
        this.threeGroup = new THREE.Group()
        this.threeGroup.name = 'domino'

        for (let i = 0; i < this.PARAMS.count; i++) {
            const domino = new THREE.Mesh(
                new THREE.BoxGeometry(
                    this.PARAMS.width, 
                    this.PARAMS.height, 
                    this.PARAMS.depth
                ),
                new THREE.MeshMatcapMaterial({
                    // matcap: 
                })
            )
            domino.position.z = i / 2
            domino.name = "domino"
            this.threeGroup.add(domino)
            this.dominoes.push(domino)
        }

        this.threeGroup.position.set(
            this.position.x + this.PARAMS.offset.x, 
            this.PARAMS.height / 2,
            this.position.y + this.PARAMS.offset.y
        )
        this.threeGroup.rotation.y = Math.PI * this.PARAMS.rotation
        this.scene.add(this.threeGroup)

    }

    triggerDominoFall() 
    {
        const delayPerDomino = 0.3

        this.dominoes.forEach((domino, index) => {
            const xRotation = index == (this.PARAMS.count - 1) ? Math.PI * 0.5 : Math.PI * 0.5 * 0.66
            
            gsap.to(domino.rotation, {
                x: xRotation, 
                duration: 2,
                delay: index * delayPerDomino,
                ease: 'power1.inOut',
                onUpdate: () => {
                    domino.position.y = -Math.sin(domino.rotation.x) * this.PARAMS.height / 2
                    
                    const colorLerp = Math.abs(Math.sin(domino.rotation.x)) 
                    const standingColor = new THREE.Color(1, 1, 1) 
                    const layingColor = new THREE.Color(0, 0.45, 1) 
                    
                    domino.material.color.lerpColors(standingColor, layingColor, colorLerp) 
                    domino.material.c
                },
                onComplete: () => {
                    if (index === this.PARAMS.count - 1) {
                        this.triggerDominoUp()
                    }
                },
            })
        })
    }

    triggerDominoUp()
    {
        const delayPerDomino = 0.3

        this.dominoes.forEach((domino, index) => {
            const xRotation = 0

            gsap.to(domino.rotation, {
                x: xRotation, 
                duration: 1,
                delay: index * delayPerDomino,
                ease: 'power1.inOut',
                onUpdate: () => {
                    domino.position.y = -Math.sin(domino.rotation.x) * this.PARAMS.height / 2

                    const colorLerp = Math.abs(Math.sin(domino.rotation.x)) 
                    const standingColor = new THREE.Color(1, 1, 1) 
                    const layingColor = new THREE.Color(0, 0.45, 1) 
                    
                    domino.material.color.lerpColors(standingColor, layingColor, colorLerp) // Interpolate colors
                
                },
                onComplete: () => {
                    if (index === this.PARAMS.count - 1) {
                        this.triggerDominoFall()
                    }
                }
            })
        })
    }

    setDebug()
    {
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder({ 
                title: 'domino',
                expanded: false
            })

            const offset = this.debugFolder.addBinding(this.PARAMS, 'offset', {
                x: { min: -10, max: 10 },
                y: { min: -10, max: 10 },
            })
            offset.on('change', (ev) => {
                this.threeGroup.position.set(
                    this.position.x + ev.value.x, 
                    0, 
                    this.position.y + ev.value.y
                )
            })

            const rotation = this.debugFolder.addBinding(this.PARAMS, 'rotation', {
                min: 0,
                max: 2,
                step: 0.05
            })
            rotation.on('change', (ev) => {
                this.threeGroup.rotation.y = Math.PI * ev.value
            })
        }
    }
}
